import Swiper from 'swiper';
import { createPopper } from '@popperjs/core';

/*********************************/
/*         INDEX                 */
/*================================
 *    01.  Swiper slider         *
 *    02.  Maintenance js        * (For Maintenance page)
 *    03.  Data Counter          *
 *    04.  Fade Animation        *
 *    05.  Typed Text animation  *
 *    06.  Components            *
 *         1. Navtabs            *
 *         2. Modal              *
 *         3. Carousel           *
 *         4. Accordions         *
 *         5. Popovers           *
 *    07. Custom Dropdown        * (For Dropdown)
 ================================*/

//=========================================//
/*            01) Swiper slider            */
//=========================================//
var menu = [];
var interleaveOffset = 0.5;
var swiperOptions = {
    loop: true,
    speed: 1000,
    parallax: true,
    autoplay: {
        delay: 6500,
        disableOnInteraction: false,
    },
    watchSlidesProgress: true,
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
        renderBullet: function (index, className) {
            return '<span class="' + className + '">' + 0 + (index + 1) + '</span>';
        },
    },

    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },

    on: {
        progress: function () {
            var swiper = this;
            for (var i = 0; i < swiper.slides.length; i++) {
                var slideProgress = swiper.slides[i].progress;
                var innerOffset = swiper.width * interleaveOffset;
                var innerTranslate = slideProgress * innerOffset;
                swiper.slides[i].querySelector(".slide-inner").style.transform =
                    "translate3d(" + innerTranslate + "px, 0, 0)";
            }
        },

        touchStart: function () {
            var swiper = this;
            for (var i = 0; i < swiper.slides.length; i++) {
                swiper.slides[i].style.transition = "";
            }
        },

        setTransition: function (speed) {
            var swiper = this;
            for (var i = 0; i < swiper.slides.length; i++) {
                swiper.slides[i].style.transition = speed + "ms";
                swiper.slides[i].querySelector(".slide-inner").style.transition =
                    speed + "ms";
            }
        }
    }
};

// DATA BACKGROUND IMAGE
var swiper = new Swiper(".swiper-container", swiperOptions);

let data = document.querySelectorAll(".slide-bg-image")
data.forEach((e) => {
    e.style.backgroundImage =
        `url(${e.getAttribute('data-background')})`;
})

//=========================================//
/*/*            02) Maintenance js         */
//=========================================//
if (document.getElementById("maintenance")) {
    var seconds = document.cookie.split("; ").find((row) => row.startsWith("maintenance_delta="))?.split("=")[1];

    function secondPassed() {
        var minutes = Math.round((seconds - 30) / 60);
        var remainingSeconds = seconds % 60;
        if (remainingSeconds < 10) {
            remainingSeconds = "0" + remainingSeconds;
        }
        document.getElementById('maintenance').innerHTML = minutes + ":" + remainingSeconds;
        if (seconds <= 0) {
            clearInterval(countdownTimer);
            document.getElementById('maintenance').innerHTML = "00:00";
        } else {
            seconds--;
        }
    }

    var countdownTimer = setInterval(secondPassed, 1000);
}

//=========================================//
/*/*            03) Data Counter           */
//=========================================//
try {
    window.addEventListener('load', () => {
        setTimeout(() => {
            const counter = document.querySelectorAll('.counter-value');

            counter.forEach(counter_value => {
                const updateCount = () => {
                    const target = +counter_value.getAttribute('data-target');
                    const speed = +counter_value.getAttribute('data-speed') ?? 10000; // Higher is slower, lower is faster
                    const count = +counter_value.innerText;

                    // Lower inc to slow and higher to fast
                    var increment = target / speed;

                    if (increment < 1) {
                        increment = 1;
                    }

                    // Check if target is reached
                    if (count < target) {
                        // Add inc to count and output in counter_value
                        counter_value.innerText = (count + increment).toFixed(0);
                        // Call function every ms
                        setTimeout(updateCount, 250);
                    } else {
                        counter_value.innerText = target;
                    }
                };

                updateCount();
            });
        }, 350);
    });
} catch (err) {

}

//=========================================//
/*/*            04) Fade Animation         */
//=========================================//
try {
    AOS.init({
        easing: 'ease-in-out-sine',
        duration: 1000
    });
} catch (err) {

}

//=========================================//
/*/* 05) Typed Text animation (animation) */
//=========================================//
try {
    var TxtType = function (el, toRotate, period) {
        this.toRotate = toRotate;
        this.el = el;
        this.loopNum = 0;
        this.period = parseInt(period, 10) || 2000;
        this.txt = '';
        this.tick();
        this.isDeleting = false;
    };

    TxtType.prototype.tick = function () {
        var i = this.loopNum % this.toRotate.length;
        var fullTxt = this.toRotate[i];
        if (this.isDeleting) {
            this.txt = fullTxt.substring(0, this.txt.length - 1);
        } else {
            this.txt = fullTxt.substring(0, this.txt.length + 1);
        }
        this.el.innerHTML = '<span class="wrap">' + this.txt + '</span>';
        var that = this;
        var delta = 200 - Math.random() * 100;
        if (this.isDeleting) {
            delta /= 2;
        }
        if (!this.isDeleting && this.txt === fullTxt) {
            delta = this.period;
            this.isDeleting = true;
        } else if (this.isDeleting && this.txt === '') {
            this.isDeleting = false;
            this.loopNum++;
            delta = 500;
        }
        setTimeout(function () {
            that.tick();
        }, delta);
    };

    function typewrite() {
        if (toRotate === 'undefined') {
            changeText()
        } else
            var elements = document.getElementsByClassName('typewrite');
        for (var i = 0; i < elements.length; i++) {
            var toRotate = elements[i].getAttribute('data-type');
            var period = elements[i].getAttribute('data-period');
            if (toRotate) {
                new TxtType(elements[i], JSON.parse(toRotate), period);
            }
        }
        // INJECT CSS
        var css = document.createElement("style");
        css.type = "text/css";
        css.innerHTML = ".typewrite > .wrap { border-right: 0.08em solid transparent}";
        document.body.appendChild(css);
    }
    window.onload(typewrite());
} catch (err) {

}

//=========================================//
/*            06) Components               */
//=========================================//

//******** 1) Navtabs ********//
try {
    const Default = {
        defaultTabId: null,
        titleClasses: 'text-indigo-600 dark:text-indigo-400',
        activeClasses: 'bg-slate-600/[.05] dark:bg-slate-800',
        inactiveClasses: 'hover:bg-slate-600/[.05] dark:hover:bg-slate-800',
        interval: 6000,
        onShow: () => {
        }
    }

    class Tabs {
        constructor(items = [], options = {}) {
            this._items = items
            this._activeTab = options ? this.getTab(options.defaultTabId) : null
            this._options = {...Default, ...options}
            this._interval = null
            this._init()
            this.cycle()
        }

        _init() {
            if (this._items.length) {
                // set the first tab as active if not set by explicitly
                if (!this._activeTab) {
                    this._setActiveTab(this._items[0])
                }

                // force show the first default tab
                this.show(this._activeTab.id, true)

                // show tab content based on click
                this._items.map(tab => {
                    tab.triggerEl.addEventListener('click', () => {
                        this.show(tab.id)
                    })
                })
            }
        }

        /**
         * Get the currently active tab
         */
        getActiveTab() {
            return this._activeTab
        }

        /**
         * Set the currently active tab and data attribute
         * @param {*} tab
         */
        _setActiveTab(tab) {
            this._activeTab = tab
        }

        getTab(id) {
            return this._items.filter(t => t.id === id)[0]
        }

        show(id, forceShow = false) {
            const tab = this.getTab(id)

            // don't do anything if already active
            if (tab === this._activeTab && !forceShow) {
                return
            }

            // hide other tabs
            this._items.map(t => {
                if (t !== tab) {
                    t.triggerEl.classList.remove(...this._options.activeClasses.split(" "));
                    t.triggerEl.classList.add(...this._options.inactiveClasses.split(" "));
                    t.triggerEl.querySelector("h5:first-of-type").classList.remove(...this._options.titleClasses.split(" "));
                    t.targetEl.classList.add('hidden')
                    t.triggerEl.setAttribute('aria-selected', false)
                }
            })

            // show active tab
            tab.triggerEl.classList.add(...this._options.activeClasses.split(" "));
            tab.triggerEl.querySelector("h5:first-of-type").classList.add(...this._options.titleClasses.split(" "));

            tab.triggerEl.classList.remove(...this._options.inactiveClasses.split(" "));

            tab.triggerEl.setAttribute('aria-selected', true)
            tab.targetEl.classList.remove('hidden')

            this._setActiveTab(tab)

            if (this._interval) {
                this.pause()
                this.cycle()
            }

            // callback function
            this._options.onShow(this, tab)
        }

        /**
         * Set an interval to cycle through the tab items
         */
        cycle() {
            this._interval = setInterval(() => {
                this.next();
            }, this._options.interval)
        }

        /**
         * Clears the cycling interval
         */
        pause() {
            clearInterval(this._interval);
        }

        /**
         * Based on the currently active item it will go to the next position
         */
        next() {
            let tabs = this

            if (document.body.scrollTop >= 300 || document.documentElement.scrollTop >= 300) {
                const activeTab = tabs.getActiveTab()
                let nextTab

                // check if last item
                if (activeTab.position === tabs._items.length - 1) {
                    nextTab = tabs._items[0]
                } else {
                    nextTab = tabs._items[activeTab.position + 1]
                }

                tabs.show(nextTab.id)
            }
        }
    }

    window.Tabs = Tabs;

    document.addEventListener('DOMContentLoaded', () => {
        document.querySelectorAll('[data-tabs-toggle]').forEach(triggerEl => {

            const tabElements = []
            let defaultTabId = null
            let tabCount = 0
            triggerEl.querySelectorAll('[role="tab"]').forEach(el => {
                const isActive = el.getAttribute('aria-selected') === 'true'
                const tab = {
                    id: el.getAttribute('data-tabs-target'),
                    position: tabCount++,
                    triggerEl: el,
                    targetEl: document.querySelector(el.getAttribute('data-tabs-target'))
                }
                tabElements.push(tab)

                if (isActive) {
                    defaultTabId = tab.id
                }
            })
            new Tabs(tabElements, {
                defaultTabId: defaultTabId
            })
        })
    })
} catch (error) {

}

//********* 2) Modals ********//
try {
    const Default = {
        placement: 'center',
        backdropClasses: 'bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40',
        onHide: () => {
        },
        onShow: () => {
        },
        onToggle: () => {
        }
    }

    class Modal {
        constructor(targetEl = null, options = {}) {
            this._targetEl = targetEl
            this._options = {...Default, ...options}
            this._isHidden = true
            this._init()
        }

        _init() {
            this._getPlacementClasses().map(c => {
                this._targetEl.classList.add(c)
            })
        }

        _createBackdrop() {
            if (this._isHidden) {
                const backdropEl = document.createElement('div');
                backdropEl.setAttribute('modal-backdrop', '');
                backdropEl.classList.add(...this._options.backdropClasses.split(" "));
                document.querySelector('body').append(backdropEl);
            }
        }

        _destroyBackdropEl() {
            if (!this._isHidden) {
                document.querySelector('[modal-backdrop]').remove();
            }
        }

        _getPlacementClasses() {
            switch (this._options.placement) {

                // top
                case 'top-left':
                    return ['justify-start', 'items-start']
                case 'top-center':
                    return ['justify-center', 'items-start']
                case 'top-right':
                    return ['justify-end', 'items-start']

                // center
                case 'center-left':
                    return ['justify-start', 'items-center']
                case 'center':
                    return ['justify-center', 'items-center']
                case 'center-right':
                    return ['justify-end', 'items-center']

                // bottom
                case 'bottom-left':
                    return ['justify-start', 'items-end']
                case 'bottom-center':
                    return ['justify-center', 'items-end']
                case 'bottom-right':
                    return ['justify-end', 'items-end']

                default:
                    return ['justify-center', 'items-center']
            }
        }

        toggle() {
            if (this._isHidden) {
                this.show()
            } else {
                this.hide()
            }

            // callback function
            this._options.onToggle(this)
        }

        show() {
            this._targetEl.classList.add('flex')
            this._targetEl.classList.remove('hidden')
            this._targetEl.setAttribute('aria-modal', 'true')
            this._targetEl.setAttribute('role', 'dialog')
            this._targetEl.removeAttribute('aria-hidden')
            this._createBackdrop()
            this._isHidden = false

            // callback function
            this._options.onShow(this)
        }

        hide() {
            this._targetEl.classList.add('hidden')
            this._targetEl.classList.remove('flex')
            this._targetEl.setAttribute('aria-hidden', 'true')
            this._targetEl.removeAttribute('aria-modal')
            this._targetEl.removeAttribute('role')
            this._destroyBackdropEl()
            this._isHidden = true

            // callback function
            this._options.onHide(this)
        }
    }

    window.Modal = Modal;

    const getModalInstance = (id, instances) => {
        if (instances.some(modalInstance => modalInstance.id === id)) {
            return instances.find(modalInstance => modalInstance.id === id)
        }
        return false
    }

    document.addEventListener('DOMContentLoaded', () => {
        let modalInstances = []
        document.querySelectorAll('[data-modal-toggle]').forEach(el => {
            const modalId = el.getAttribute('data-modal-toggle');
            const modalEl = document.getElementById(modalId);
            const placement = modalEl.getAttribute('data-modal-placement')

            if (modalEl) {
                if (!modalEl.hasAttribute('aria-hidden') && !modalEl.hasAttribute('aria-modal')) {
                    modalEl.setAttribute('aria-hidden', 'true');
                }
            }

            let modal = null
            if (getModalInstance(modalId, modalInstances)) {
                modal = getModalInstance(modalId, modalInstances)
                modal = modal.object
            } else {
                modal = new Modal(modalEl, {
                    placement: placement ? placement : Default.placement
                })
                modalInstances.push({
                    id: modalId,
                    object: modal
                })
            }

            el.addEventListener('click', () => {
                modal.toggle()
            })
        })
    })
} catch (error) {

}

//******** 3) Carousel *******//
try {
    const Default = {
        defaultPosition: 0,
        indicators: {
            items: [],
            activeClasses: 'bg-white dark:bg-gray-800',
            inactiveClasses: 'bg-white/50 dark:bg-gray-800/50 hover:bg-white dark:hover:bg-gray-800'
        },
        interval: 6000,
        onNext: () => {
        },
        onPrev: () => {
        },
        onChange: () => {
        }
    }

    class Carousel {
        constructor(items = [], options = {}) {
            this._items = items
            this._options = {...Default, ...options, indicators: {...Default.indicators, ...options.indicators}}
            this._activeItem = this.getItem(this._options.defaultPosition)
            this._indicators = this._options.indicators.items
            this._interval = null
            this._init()
            this.cycle()
        }

        /**
         * Initialise carousel and items based on active one
         */
        _init() {
            this._items.map(item => {
                item.el.classList.add('absolute', 'inset-0', 'transition-all', 'transform')
            })

            // if no active item is set then first position is default
            if (this._getActiveItem()) {
                this.slideTo(this._getActiveItem().position)
            } else {
                this.slideTo(0)
            }

            this._indicators.map((indicator, position) => {
                indicator.el.addEventListener('click', () => {
                    this.slideTo(position)
                })
            })
        }

        getItem(position) {
            return this._items[position]
        }

        /**
         * Slide to the element based on id
         * @param {*} position
         */
        slideTo(position) {
            const nextItem = this._items[position]
            const rotationItems = {
                'left': nextItem.position === 0 ? this._items[this._items.length - 1] : this._items[nextItem.position - 1],
                'middle': nextItem,
                'right': nextItem.position === this._items.length - 1 ? this._items[0] : this._items[nextItem.position + 1]
            }
            this._rotate(rotationItems)
            this._setActiveItem(nextItem.position)
            if (this._interval) {
                this.pause()
                this.cycle()
            }
            this._options.onChange(this)
        }

        /**
         * Based on the currently active item it will go to the next position
         */
        next() {
            const activeItem = this._getActiveItem()
            let nextItem = null

            // check if last item
            if (activeItem.position === this._items.length - 1) {
                nextItem = this._items[0]
            } else {
                nextItem = this._items[activeItem.position + 1]
            }

            this.slideTo(nextItem.position)
            // callback function
            this._options.onNext(this)
        }

        /**
         * Based on the currently active item it will go to the previous position
         */
        prev() {
            const activeItem = this._getActiveItem()
            let prevItem = null

            // check if first item
            if (activeItem.position === 0) {
                prevItem = this._items[this._items.length - 1]
            } else {
                prevItem = this._items[activeItem.position - 1]
            }

            this.slideTo(prevItem.position)

            // callback function
            this._options.onPrev(this)
        }

        /**
         * This method applies the transform classes based on the left, middle, and right rotation carousel items
         * @param {*} rotationItems
         */
        _rotate(rotationItems) {
            // reset
            this._items.map(item => {
                item.el.classList.add('hidden')
            })

            // left item (previously active)
            rotationItems.left.el.classList.remove('-translate-x-full', 'translate-x-full', 'translate-x-0', 'hidden', 'z-20')
            rotationItems.left.el.classList.add('-translate-x-full', 'z-10')

            // currently active item
            rotationItems.middle.el.classList.remove('-translate-x-full', 'translate-x-full', 'translate-x-0', 'hidden', 'z-10')
            rotationItems.middle.el.classList.add('translate-x-0', 'z-20')

            // right item (upcoming active)
            rotationItems.right.el.classList.remove('-translate-x-full', 'translate-x-full', 'translate-x-0', 'hidden', 'z-20')
            rotationItems.right.el.classList.add('translate-x-full', 'z-10')
        }

        /**
         * Set an interval to cycle through the carousel items
         */
        cycle() {
            this._interval = setInterval(() => {
                this.next();
            }, this._options.interval)
        }

        /**
         * Clears the cycling interval
         */
        pause() {
            clearInterval(this._interval);
        }

        /**
         * Get the currently active item
         */
        _getActiveItem() {
            return this._activeItem
        }

        /**
         * Set the currently active item and data attribute
         * @param {*} position
         */
        _setActiveItem(position) {
            this._activeItem = this._items[position]

            // update the indicators if available
            if (this._indicators.length) {
                this._indicators.map(indicator => {
                    indicator.el.setAttribute('aria-current', 'false')
                    indicator.el.classList.remove(...this._options.indicators.activeClasses.split(" "))
                    indicator.el.classList.add(...this._options.indicators.inactiveClasses.split(" "))
                })
                this._indicators[position].el.classList.add(...this._options.indicators.activeClasses.split(" "))
                this._indicators[position].el.classList.remove(...this._options.indicators.inactiveClasses.split(" "))
                this._indicators[position].el.setAttribute('aria-current', 'true')
            }
        }
    }

    window.Carousel = Carousel;

    document.addEventListener('DOMContentLoaded', () => {
        document.querySelectorAll('[data-carousel]').forEach(carouselEl => {
            const interval = carouselEl.getAttribute('data-carousel-interval')
            const slide = carouselEl.getAttribute('data-carousel') === 'slide' ? true : false

            const items = []
            let defaultPosition = 0
            if (carouselEl.querySelectorAll('[data-carousel-item]').length) {
                [...carouselEl.querySelectorAll('[data-carousel-item]')].map((carouselItemEl, position) => {
                    items.push({
                        position: position,
                        el: carouselItemEl
                    })

                    if (carouselItemEl.getAttribute('data-carousel-item') === 'active') {
                        defaultPosition = position
                    }
                })
            }

            const indicators = [];
            if (carouselEl.querySelectorAll('[data-carousel-slide-to]').length) {
                [...carouselEl.querySelectorAll('[data-carousel-slide-to]')].map((indicatorEl) => {
                    indicators.push({
                        position: indicatorEl.getAttribute('data-carousel-slide-to'),
                        el: indicatorEl
                    })
                })
            }

            const carousel = new Carousel(items, {
                defaultPosition: defaultPosition,
                indicators: {
                    items: indicators
                },
                interval: interval ? interval : Default.interval
            })

            if (slide) {
                carousel.cycle();
            }

            // check for controls
            const carouselNextEl = carouselEl.querySelector('[data-carousel-next]')
            const carouselPrevEl = carouselEl.querySelector('[data-carousel-prev]')

            if (carouselNextEl) {
                carouselNextEl.addEventListener('click', () => {
                    carousel.next()
                })
            }

            if (carouselPrevEl) {
                carouselPrevEl.addEventListener('click', () => {
                    carousel.prev()
                })
            }
        })
    })
} catch (error) {

}

//******* 4) Accordions ******//
try {
    const Default = {
        alwaysOpen: false,
        activeClasses: 'bg-gray-50 dark:bg-slate-800 text-indigo-600',
        inactiveClasses: 'text-dark dark:text-white',
        onOpen: () => {
        },
        onClose: () => {
        },
        onToggle: () => {
        }
    }

    class Accordion {
        constructor(items = [], options = {}) {
            this._items = items
            this._options = {...Default, ...options}
            this._init()
        }

        _init() {
            if (this._items.length) {
                // show accordion item based on click
                this._items.map(item => {

                    if (item.active) {
                        this.open(item.id)
                    }

                    item.triggerEl.addEventListener('click', () => {
                        this.toggle(item.id)
                    })
                })
            }
        }

        getItem(id) {
            return this._items.filter(item => item.id === id)[0]
        }

        open(id) {
            const item = this.getItem(id)

            // don't hide other accordions if always open
            if (!this._options.alwaysOpen) {
                this._items.map(i => {
                    if (i !== item) {
                        i.triggerEl.classList.remove(...this._options.activeClasses.split(" "))
                        i.triggerEl.classList.add(...this._options.inactiveClasses.split(" "))
                        i.targetEl.classList.add('hidden')
                        i.triggerEl.setAttribute('aria-expanded', false)
                        i.active = false

                        // rotate icon if set
                        if (i.iconEl) {
                            i.iconEl.classList.remove('rotate-180')
                        }
                    }
                })
            }

            // show active item
            item.triggerEl.classList.add(...this._options.activeClasses.split(" "))
            item.triggerEl.classList.remove(...this._options.inactiveClasses.split(" "))
            item.triggerEl.setAttribute('aria-expanded', true)
            item.targetEl.classList.remove('hidden')
            item.active = true

            // rotate icon if set
            if (item.iconEl) {
                item.iconEl.classList.add('rotate-180')
            }

            // callback function
            this._options.onOpen(this, item)
        }

        toggle(id) {
            const item = this.getItem(id)

            if (item.active) {
                this.close(id)
            } else {
                this.open(id)
            }

            // callback function
            this._options.onToggle(this, item)
        }

        close(id) {
            const item = this.getItem(id)

            item.triggerEl.classList.remove(...this._options.activeClasses.split(" "))
            item.triggerEl.classList.add(...this._options.inactiveClasses.split(" "))
            item.targetEl.classList.add('hidden')
            item.triggerEl.setAttribute('aria-expanded', false)
            item.active = false

            // rotate icon if set
            if (item.iconEl) {
                item.iconEl.classList.remove('rotate-180')
            }

            // callback function
            this._options.onClose(this, item)
        }
    }

    window.Accordion = Accordion;

    document.addEventListener('DOMContentLoaded', () => {
        document.querySelectorAll('[data-accordion]').forEach(accordionEl => {

            const alwaysOpen = accordionEl.getAttribute('data-accordion')
            const activeClasses = accordionEl.getAttribute('data-active-classes')
            const inactiveClasses = accordionEl.getAttribute('data-inactive-classes')

            const items = []
            accordionEl.querySelectorAll('[data-accordion-target]').forEach(el => {
                const item = {
                    id: el.getAttribute('data-accordion-target'),
                    triggerEl: el,
                    targetEl: document.querySelector(el.getAttribute('data-accordion-target')),
                    iconEl: el.querySelector('[data-accordion-icon]'),
                    active: el.getAttribute('aria-expanded') === 'true' ? true : false
                }
                items.push(item)
            })

            new Accordion(items, {
                alwaysOpen: alwaysOpen === 'open' ? true : false,
                activeClasses: activeClasses ? activeClasses : Default.activeClasses,
                inactiveClasses: inactiveClasses ? inactiveClasses : Default.inactiveClasses
            })
        })
    })
} catch (error) {

}

//******* 5) Popovers ******//
try {
    const Default = {
        placement: 'top',
        offset: 10,
        triggerType: 'hover',
        onShow: () => {
        },
        onHide: () => {
        },
    };

    class Popover {
        constructor(targetEl = null, triggerEl = null, options = {}) {
            this._targetEl = targetEl
            this._triggerEl = triggerEl
            this._options = { ...Default, ...options }
            this._popperInstance = this._createPopperInstance()
            this._init()
        }

        _init() {
            if (this._triggerEl) {
                const triggerEvents = this._getTriggerEvents()
                triggerEvents.showEvents.forEach(ev => {
                    this._triggerEl.addEventListener(ev, () => {
                        this.show()
                    })
                    this._targetEl.addEventListener(ev, () => {
                        this.show()
                    })
                })
                triggerEvents.hideEvents.forEach(ev => {
                    this._triggerEl.addEventListener(ev, () => {
                        setTimeout(() => {
                            if (!this._targetEl.matches(':hover')) {
                                this.hide()
                            }
                        }, 100)
                    })
                    this._targetEl.addEventListener(ev, () => {
                        setTimeout(() => {
                            if (!this._triggerEl.matches(':hover')) {
                                this.hide()
                            }
                        }, 100)
                    })
                })
            }
        }

        _createPopperInstance() {
            return createPopper(this._triggerEl, this._targetEl, {
                placement: this._options.placement,
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, this._options.offset],
                        },
                    },
                ],
            });
        }

        _getTriggerEvents() {
            switch (this._options.triggerType) {
                case 'hover':
                    return {
                        showEvents: ['mouseenter', 'focus'],
                        hideEvents: ['mouseleave', 'blur']
                    }
                case 'click':
                    return {
                        showEvents: ['click', 'focus'],
                        hideEvents: ['focusout', 'blur']
                    }
                default:
                    return {
                        showEvents: ['mouseenter', 'focus'],
                        hideEvents: ['mouseleave', 'blur']
                    }
            }
        }

        show() {
            this._targetEl.classList.remove('opacity-0', 'invisible')
            this._targetEl.classList.add('opacity-100', 'visible')

            // Enable the event listeners
            this._popperInstance.setOptions(options => ({
                ...options,
                modifiers: [
                    ...options.modifiers,
                    { name: 'eventListeners', enabled: true },
                ],
            }));

            // Update its position
            this._popperInstance.update()

            // callback function
            this._options.onShow(this)
        }

        hide() {
            this._targetEl.classList.remove('opacity-100', 'visible')
            this._targetEl.classList.add('opacity-0', 'invisible')

            // Disable the event listeners
            this._popperInstance.setOptions(options => ({
                ...options,
                modifiers: [
                    ...options.modifiers,
                    { name: 'eventListeners', enabled: false },
                ],
            }));

            // callback function
            this._options.onHide(this)
        }
    }

    window.Popover = Popover;

    document.addEventListener('DOMContentLoaded', () => {
        document.querySelectorAll('[data-popover-target]').forEach(triggerEl => {
            const targetEl = document.getElementById(triggerEl.getAttribute('data-popover-target'))
            const triggerType = triggerEl.getAttribute('data-popover-trigger');
            const placement = triggerEl.getAttribute('data-popover-placement');
            const offset = triggerEl.getAttribute('data-popover-offset');

            new Popover(targetEl, triggerEl, {
                placement: placement ? placement : Default.placement,
                offset: offset ? parseInt(offset) : Default.offset,
                triggerType: triggerType ? triggerType : Default.triggerType
            })
        })
    })
} catch (error) {

}

//=========================================//
/*            07) Custom Dropdown          */
//=========================================//
document.querySelectorAll(".dropdown").forEach(function (item) {
    item.querySelectorAll(".dropdown-toggle").forEach(function (subitem) {
        subitem.addEventListener("click", function (event) {
            subitem.classList.toggle("block");
            if (subitem.classList.contains("block") != true) {
                item.querySelector(".dropdown-menu").classList.remove("block")
                item.querySelector(".dropdown-menu").classList.add("hidden")
            } else {
                dismissDropdownMenu()
                item.querySelector(".dropdown-menu").classList.add("block")
                item.querySelector(".dropdown-menu").classList.remove("hidden")
                if (item.querySelector(".dropdown-menu").classList.contains("block")) {
                    subitem.classList.add("block")
                } else {
                    subitem.classList.remove("block")
                }
                event.stopPropagation();
            }
        });
    });
});

function dismissDropdownMenu() {
    document.querySelectorAll(".dropdown-menu").forEach(function (item) {
        item.classList.remove("block")
        item.classList.add("hidden")
    });
    document.querySelectorAll(".dropdown-toggle").forEach(function (item) {
        item.classList.remove("block")
    });
}

window.addEventListener('click', function (e) {
    dismissDropdownMenu();
});

// dd-menu
var ddmenu = document.getElementsByClassName("dd-menu");
for (var i = 0, len = ddmenu.length; i < len; i++) {
    ddmenu[i].onclick = function (elem) {
        elem.stopPropagation();
    }
}
